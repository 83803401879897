
import { defineComponent } from "vue";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import Textarea from "primevue/textarea";
import AddOpperationDialog from "./AddOperationDialog.vue";
import { OperationItem } from "@/types/controls/fieldService";
import InputNumber from "primevue/inputnumber";
import Signature from "../UI/Signature.vue";
import Dialog from "primevue/dialog";

export default defineComponent({
  name: "Logs",
  components: {
    Card,
    InputText,
    Button,
    Dropdown,
    InputSwitch,
    InputNumber,
    DataTable,
    Column,
    Textarea,
    AddOpperationDialog,
    Dialog,
    Signature,
  },
  props: {
    defaultFieldService: {
      type: Object,
      default: () => ({} as any),
    },
  },
  data: () => ({
    currentId: "",
    fieldService: {} as any,
    notes: "",
    showAddOperationDialog: false,
    visibleSignatureDialog: false,
  }),
  created() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
    }
    if (this.$route.params.order_id) {
      this.currentId = this.$route.params.order_id as string;
    }
  },
  updated() {
    this.updateFieldService();
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getActiveFieldService: "fieldServices/getActiveFieldService",
      allowEditOperations: "fieldServiceControl/allowEditOperations",
      operations: "fieldServiceControl/getOperations",
      reportTypes: "fieldServiceControl/getReportTypes",
      getActiveTab: "fieldServices/getActiveTab",
    }),
    isNewFieldService(): boolean {
      return (
        this.fieldService.order_no != null &&
        this.fieldService.order_no.startsWith("New")
      ) || this.currentId.toLowerCase().includes("new");
    },
    isSignatureReadOnly(): boolean {
      return !this.isNewFieldService || this.fieldService.signature == "";
    },
    signatureButtonLabel(): string {
      return this.fieldService.signature ? "View Signature" : "Add Signature";
    },
  },
  mounted() {
    this.updateFieldService();
  },
  methods: {
    ...mapActions({
      fetchControls: "control/fetchControl",
    }),
    addOperation() {
      this.showAddOperationDialog = true;
    },
    updateFieldService() {
      if (this.getActiveFieldService && this.getActiveFieldService.record) {
        this.fieldService = this.getActiveFieldService.record;
        if (!this.fieldService.oper_li_items) {
          this.fieldService.oper_li_items = [];
        }
        this.setNotes(this.fieldService.notes_items);
      } else {
        this.fieldService = this.defaultFieldService;
        if (!this.fieldService.oper_li_items) {
          this.fieldService.oper_li_items = [];
        }
        this.setNotes(this.fieldService.notes_items);
      }
    },
    setNotes(notesList: any) {
      this.notes = "";
      const note_list =
        notesList?.map((item: any) => {
          return item.notes;
        }) || [];
      this.notes = note_list.join("\n");
    },
    operationDesc(type: string) {
      const data = this.operations.find((item: any) => item.operation === type);
      if (data) {
        return data.operation_description || data.operation;
      } else {
        return "";
      }
    },
    getOperationData(type: string) {
      const data = this.operations.filter(
        (item: any) => item.operation === type
      );
      if (data.length > 0) {
        return data[0];
      } else {
        return { input_type: "TEXT" };
      }
    },
    handleAddOperation(data: any) {
      this.showAddOperationDialog = false;

      const operation: OperationItem = this.operations.find(
        (item: OperationItem) => item.operation === data.operation
      );
      if (operation) {
        this.fieldService.oper_li_items.push({
          oper_li: (this.fieldService.oper_li_items.length + 1).toString(),
          operation: operation.operation,
          oper_desc: operation.operation_description,
          oper_notes: data.oper_notes || operation.default_value || "",
        });
      }
    },
    addOperations() {
      if (!this.allowEditOperations) {
        this.fieldService.oper_li_items = [];
      }
      this.operations.forEach((item: any) => {
        let hasType = false;
        if (item.op_report_type_items) {
          hasType = item.op_report_type_items.some(
            (item: any) => item.op_report_type === this.fieldService.order_type
          );
        }

        if (hasType) {
          this.fieldService.oper_li_items.push({
            oper_li: (this.fieldService.oper_li_items.length + 1).toString(),
            operation: item.operation,
            oper_desc: item.operation_description,
            oper_notes: item.default_value || null,
            oper_label: item.oper_label || "",
          });
        }
      });
    },
    removeOperation(oper: any) {
      this.fieldService.oper_li_items = this.fieldService.oper_li_items.filter(
        (item: any) => item.oper_li !== oper.oper_li
      );
    },
    updateGroup() {
      this.fieldService.oper_li_items?.map((item: any) => {
        const op = this.operations.find(
          (oper: any) => oper.operation === item.operation
        );

        if (op && op.web_group) {
          item["web_group"] = op.web_group;
        } else {
          item["web_group"] = "No Group";
        }
      });
    },
    showSignatureDialog() {
      this.visibleSignatureDialog = true;
    },
    saveSignature(data: any) {
      this.fieldService.signature = data.signature;
      this.fieldService.signature_type = data.signatureType;
      this.fieldService.signature_by = data.receivedBy;
      this.fieldService.signature_date = data.receivedDate;
      this.fieldService.signature_time = data.receivedTime;
      this.visibleSignatureDialog = false;
    },
  },
  watch: {
    getActiveTab: {
      handler() {
        this.updateFieldService();
      },
      deep: true,
    },
    fieldService: {
      handler() {
        const hasWebGroup = this.fieldService.oper_li_items?.every(
          (item: any) => item.web_group
        );
        if (!hasWebGroup) {
          this.updateGroup();
        }
      },
      deep: true,
    },
    notes: {
      handler() {
        const notes = this.notes.split("\n");
        this.fieldService.notes_items = [];
        notes.forEach((item: any) => {
          this.fieldService.notes_items.push({
            notes: item,
          });
        });
      },
      deep: true,
    },
  },
});
