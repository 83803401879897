import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import qs from "qs";
import ClientAPI from "./ClientAPI";

export default class SerialService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getSerials(
    client: any,
    ids: string,
    custs: string,
    fieldnames: string,
    correls: string,
    status: string,
    rangeStart: string,
    rangeEnd: string,
    dateStart: string,
    dateEnd: string,
    sortBy: string,
    sortOrder: string
  ) {
    return ClientAPI.Get(`serials`, {
      Client: client,
      ids,
      custs,
      fieldnames,
      correls,
      status,
      rangeStart,
      rangeEnd,
      dateStart,
      dateEnd,
      sortBy,
      sortOrder,
    });
  }

  async getFieldServiceById(
    client: any,
    id: string,
    fieldnames: string,
    correls: string
  ) {
    return ClientAPI.Get(`serials/${id}`, {
      Client: client,
      fieldnames,
      correls,
    });
  }


}
