
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Address',
  components: {
  },
  props: {
    address: {
      type: Array,
    },
    street: {
      type: Array,
    },
    city: {
      type: String,
    },
    state: {
      type: String,
    },
    zip: {
      type: String,
    },
    country: {
      type: String,
    },
  },
  data() {
    return {
      open: true,
    };
  },
})
