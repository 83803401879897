
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import {defineComponent} from "vue";

import { mapGetters, mapActions } from "vuex";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import { OperationItem } from "@/types/controls/fieldService";

export default defineComponent({
  name: "AddOperationDialog",
  components: {
    Dialog,
    Button,
    Dropdown,
    InputText,
    InputSwitch,
  },
  props: {
    show: Boolean,
    message: {
      type: String,
      default() {
        return "Are you sure you want to delete this record permanently?";
      },
    },
    header: {
      type: String,
      default() {
        return "Confirm Changes";
      },
    },
  },
  emits: ["addOperation"],
  computed: {
    ...mapGetters({
      operations: "fieldServiceControl/getOperations",
    }),
  },
  data() {
    return {
      operation: "",
      oper_notes: "",
    };
  },
  methods: {
    getOperationData(type: string) {
      const data = this.operations.filter(
        (item: any) => item.operation === type
      );
      if (data.length > 0) {
        return data[0];
      } else {
        return { input_type: "TEXT" };
      }
    },
    handleOperationChange() {
      const operation: OperationItem = this.operations.find(
        (item: OperationItem) => item.operation === this.operation
      );
      if (operation) {
        this.oper_notes = operation.default_value || "";
      }
    },
    addOperation() {
      this.$emit("addOperation", {
        operation: this.operation,
        oper_notes: this.oper_notes,
      });
    },
    cancel() {
      this.$emit("addOperation", {});
    },
  },
});
