
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import { EquipmentDesc, FieldServiceOrder } from "@/types/state/fieldServices";
import Customer from "@/types/customer";

import CustomerService from "@/services/CustomerService";
import Calendar from "primevue/calendar";
import Utils from "@/utility/utils";
import SearchFile from "@/components/SearchFile.vue";
import { Fdict } from "@/types/fdict";
import SerialService from "@/services/SerialService";
import FieldServiceOrderService from "@/services/FieldServiceOrderService";
import WorkPerformed from "./WorkPerformed.vue";
import Address from "../UI/Address.vue";
import MessageBox from "../MessageBox.vue";
import Textarea from "primevue/textarea";
import Attachments from "@/components/Attachments/Attachments.vue";
import RepDataTable from "@/components/Reps/RepDataTable.vue";
import ProgressSpinner from "primevue/progressspinner";
import ClientAPI from "@/services/ClientAPI";

const serialService = new SerialService(process.env.VUE_APP_ABSTRACTION_API);
const fieldServiceOrderService = new FieldServiceOrderService(
  process.env.VUE_APP_ABSTRACTION_API
);

const customerService = new CustomerService();

export default defineComponent({
  name: "General",
  props: {
    id: String,
    cust_id: {
      type: String,
      default: "",
    },
  },
  components: {
    SplitButton,
    Button,
    ProgressSpinner,
    InputText,
    Card,
    CollapsibleSection,
    Calendar,
    Dropdown,
    SearchFile,
    WorkPerformed,
    Address,
    Textarea,
    Attachments,
    RepDataTable,
    MessageBox,
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getActiveFieldService: "fieldServices/getActiveFieldService",
      getActiveTab: "fieldServices/getActiveTab",
      getUser: "session/getUser",
      showRepsSection: "fieldServiceControl/showRepsSection",
      showDatesSection: "fieldServiceControl/showDatesSection",
      signatureRequired: "fieldServiceControl/signatureRequired",
      getFsoIds: "customerInquiry/getFsoIds",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
    }),
    isNewFieldService(): boolean {
      return (this.fieldService.order_no != null && this.fieldService.order_no.startsWith("New")) || this.$route.params.order_id === 'new-service';
    },
    hasChanged() {
      return !Utils.compareTwoObjects(this.fieldService, this.fieldServiceCopy, ["web_group"]);
    },
  },
  async mounted() {
    this.updateActiveFieldService();

    const queryCust = this.$route.query.cust_id ?? this.$route.params.cust_id;

    if(queryCust != this.customer.cust_id && queryCust != null) {
      await customerService
        .getCustomer(queryCust, this.getClient, "contact_name contact_email")
        .then((response) => {
          this.setCustomerInformation(response as Customer);
        });
    }

    if (this.$route.params.order_id === 'new-service') {
      this.fieldService.order_no = 'New';
    }
    this.fieldServiceCopy = JSON.parse(JSON.stringify(this.fieldService));
  },
  created() {
    if (this.$attrs["current-view"] === "customer-field-service") {
      this.isLoadingCustomerInfo = true;
    }
    this.customer.address_items = [{ address: "" }];
    
    if (this.cust_id) {
      customerService
        .getCustomer(this.cust_id, this.getClient, "contact_name contact_email")
        .then((response) => {
          this.setCustomerInformation(response as Customer);
        }).finally(() => {
          this.isLoadingCustomerInfo = false;
        })
    }

    this.getFdicts("FSO").then((resp) => {
      if (resp.fdict_items) {
        this.fieldServiceOrderFdict = resp.fdict_items[0] as Fdict;
      }
    }).catch(() => {
      this.fieldServiceOrderFdict = {} as Fdict;
    });

    this.openTabSections();
  },
  data() {
    return {
      fieldService: {} as FieldServiceOrder,
      fieldServiceCopy: {} as FieldServiceOrder,
      customer: {} as Customer,
      fieldServiceOrderFdict: {} as any,
      statusOptions: [
        { status: "Posted", initial: "P" },
        { status: "Complete", initial: "C" },
        { status: "Open", initial: "O" },
      ],
      startTime: new Date() as Date | null,
      endTime: null as Date | null,
      typeOptions: [],
      serialOptions: [],
      loading: false,
      equipment_notes: "",
      isFieldServiceOrderIconDown: false,
      isEquipmentInformationIconDown: false,
      isWorkPerformedIconDown: false,
      isCustomerInformationIconDown: false,
      visibleSignatureDialog: false,
      isAttachmentsIconDown: false,
      isLoadingCustomerInfo: false,
      to: '',
      showConfirmDialog: false,
      leaveAccepted: false,
      splitButtonItems: [
        {
          label: "Print PDF",
          icon: "pi pi-print",
          command: () => {
            this.printPDF();
          },
        },
      ] as any,
    };
  },
  methods: {
    ...mapActions({
      fetchContracts: "customerInquiry/getContracts",
      fetchTerms: "customerInquiry/getTerms",
      validateCustomerAddress: "customerInquiry/validateCustomerAddress",
      updateField: "customerInquiry/updateField",
      getFdicts: "fdict/fetchFdict",
      addNotification: "notification/add",
    }),
    printPDF() {
      const id = this.fieldService.order_no;
      const reportType = this.fieldService.order_type;
      
      fieldServiceOrderService
        .getFieldServicePDF(id, this.getClient, "", reportType)
        .then((resp: any) => {
          
          const bufferArray = Utils.base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: error,
          };
          this.addNotification(notification);
        });
    },
    handleSubmitFieldService() {
      if (this.hasChanged && !this.fieldService.order_no.startsWith("New")) {
        
        let notification = {
          type: "success",
          message: `Field Service Order ${this.fieldService.order_no} has been updated.`,
        };
        this.addNotification(notification);
        this.$router.push("/customers/fieldservices");
      } else {
        
        if (this.fieldService.order_no.startsWith("New")) {
          // For new field service
          if (this.signatureRequired && !this.fieldService.signature) {
            this.addNotification({
              type: "error",
              message: "Signature is required.",
            });
            return;
          }

          const oldId = this.fieldService.order_no;
          this.fieldService.order_no = null as any;
          if (!this.fieldService.end_time) {
            this.fieldService.end_time = Utils.formatTime(new Date());
          }

          fieldServiceOrderService
            .createFieldService(this.fieldService, () => ClientAPI.displaySuccessNotification("Field Service Order created successfully."))
            .then((res: any) => {
              this.fieldService.order_no = res.recordId ?? "";
              this.fieldServiceCopy = JSON.parse(
                JSON.stringify(this.fieldService)
              );

              const ids = this.getFsoIds(this.getCurrentCustTabIndex)
              ids.push(this.fieldService.order_no)

            })
            .catch((err) => {
              // If there is any error while creating the field service order, then revert the order number to the old one
              this.fieldService.order_no = oldId;
            })
            .finally(() => {
              this.fieldServiceCopy = JSON.parse(
                JSON.stringify(this.fieldService)
              );
              this.$router.push(`/customers/fieldservices`);
            });
        } else {
          // For existing field service
          
          const id = this.fieldService.order_no;
          this.fieldService.service_date = Utils.formatDate(this.fieldService.service_date);
          this.fieldService.sched_date = Utils.formatDate(this.fieldService.sched_date);

          fieldServiceOrderService
            .updateFieldService(id, this.fieldServiceCopy, this.fieldService, this.getClient)
            .then((res: any) => {
              
              this.addNotification({
                type: "success",
                message: `Field Service Order #${id} updated successfully.`,
              })

              fieldServiceOrderService
                .getFieldServiceById(this.getClient, id, "", "")
                .then((res: any) => {
                  if (res.fso_items[0]) {
                    const fso = res.fso_items[0];
                    this.fieldServiceCopy = JSON.parse(
                      JSON.stringify(fso)
                    );
                    this.fieldService = JSON.parse(
                      JSON.stringify(fso)
                    );
                  }
                })
                .finally(() => {
                  this.$router.push(`/customers/fieldservices`);
                });
            })
        }
      }

    },
    handleCancelLeaveView() {
      this.showConfirmDialog = false;
    },
    handleAcceptLeaveView() {
      this.leaveAccepted = true;
      this.$router.push(this.to);
    },
    openTabSections() {
      this.isFieldServiceOrderIconDown = true;
      this.isEquipmentInformationIconDown = true;
      this.isCustomerInformationIconDown = true;
      this.isWorkPerformedIconDown = true;
      this.isAttachmentsIconDown = true;
    },
    formatDate(event: any, field: string) {
      (this.fieldService as any)[field] = Utils.formatDate(event);
    },
    updateCustomer(event: any) {
      customerService
        .getCustomer(event.cust_id, this.getClient, "contact_name contact_email")
        .then((response) => {
          this.setCustomerInformation(response as Customer);
        });
    },
    setCustomerInformation(customer: Customer) {
      this.customer = JSON.parse(JSON.stringify(customer));
      if (this.$attrs['current-view'] !== "customer-field-service" && this.getActiveFieldService) {
        this.getActiveFieldService.customer = customer;
      }
      this.getSerialOptions();
      this.fieldService.cust_no = this.customer.cust_id || "";
      this.fieldService.address_items = this.customerAddress(this.customer).map((address: string) => {
        return { address: address }
      });

      const repItems = JSON.parse(JSON.stringify(this.customer.rep_items || []));

      this.fieldService.rep_items = repItems

      if(this.customer.contact_id_items && this.customer.contact_id_items.length > 0) {
        this.fieldService.contact = this.customer.contact_id_items[0].contact_name || "";
      }
      this.fieldService.phone = this.customer.phone || "";
      if(this.isNewFieldService) {
        this.fieldService.service_unit = this.getUser.user_id;
        this.fieldService.service_date = Utils.formatDate(new Date());
      }
    },
    getSerialOptions() {
      serialService
        .getSerials(
          this.getClient,
          "",
          this.customer.cust_id as string,
          "serial_id serial_number part",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
        .then((res: any) => {
          this.serialOptions = res.serial_items;
        });
    },
    updateReps(reps: any) {
      this.fieldService.rep_items = reps;
    },
    toggleIcon(tab: string) {
      switch (tab) {
        case "FieldServiceOrder":
          this.isFieldServiceOrderIconDown = !this.isFieldServiceOrderIconDown;
          break;
        case "EquipmentInformation":
          this.isEquipmentInformationIconDown =
            !this.isEquipmentInformationIconDown;
          break;
        case "CustomerInformation":
          this.isCustomerInformationIconDown =
            !this.isCustomerInformationIconDown;
          break;
        case "WorkPerformed":
          this.isWorkPerformedIconDown =
            !this.isWorkPerformedIconDown;
          break;
        default:
          tab;
      }
    },
    customerAddress(customer: Customer): Array<string> {
      return Utils.customerShippingAddress(customer);
    },
    async updateActiveFieldService() {
      this.customer = {} as Customer;

      if (this.getActiveFieldService) {
        this.fieldService = this.getActiveFieldService.record;
      } else {
        const response: any = await fieldServiceOrderService.getFieldServices( this.getClient, (this.$route.params.order_id as string) || "", "", "", "cust_name", "", "1", "50", "", "", "", "", "", "")
        if (response && response.fso_items && response.fso_items.length > 0) {
          this.fieldService = response.fso_items[0] as FieldServiceOrder;
        }
      }

      if(this.getActiveFieldService && this.getActiveFieldService.customer?.cust_id) {
        this.customer = this.getActiveFieldService.customer;
      }

      this.equipment_notes = "";
      this.fieldService.equipment_desc_items?.forEach((item: EquipmentDesc) => {
        this.equipment_notes += item.equipment_desc + "\n";
      });

      if (this.customer.cust_id != this.fieldService.cust_no) {
        customerService
          .getCustomer(this.fieldService.cust_no, this.getClient, "contact_name contact_email")
          .then((response) => {
            this.setCustomerInformation(response as Customer);
          });
      }

      const date = new Date();
      if (this.isNewFieldService) {

        if (!this.fieldService.order_date) {
          this.fieldService.order_date = Utils.formatDate(date);
        }

        if (!this.fieldService.sched_date) {
          this.fieldService.sched_date = Utils.formatDate(date);
        }
  
        if (!this.fieldService.status) {
          this.fieldService.status = "O"
        }

        if (!this.fieldService.hold_date) {
          this.fieldService.hold_date = Utils.formatDate(date);
        }

        if (!this.fieldService.service_date) {
          this.fieldService.service_date = Utils.formatDate(date);
        }

        if(!this.fieldService.start_time) {
          this.fieldService.start_time = Utils.formatTime(date);
          this.startTime = Utils.convertRoverTimeToDate(this.fieldService.start_time);
        }

        if (!this.fieldService.service_unit) {
          this.fieldService.service_unit = this.getUser.user_id;
        }

        if(!this.fieldService.order_type){
          this.fieldService.order_type = "";
        }
      } else {
        this.startTime = Utils.convertRoverTimeToDate(this.fieldService.start_time);
        this.endTime = Utils.convertRoverTimeToDate(this.fieldService.end_time);

      }
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.$attrs['current-view']=== "customer-field-service" && !this.hasChanged && !this.leaveAccepted) {
      this.to = to.fullPath;
      this.showConfirmDialog = true;
    } else {
      next();
    }
  },
  watch: {
    getActiveTab: {
      handler() {
        this.updateActiveFieldService();
      },
      deep: true,
    },
    equipment_notes: {
      handler() {
        const notes = this.equipment_notes.split("\n");
        this.fieldService.equipment_desc_items = [];
        notes.forEach((item: any) => {
          this.fieldService.equipment_desc_items.push({
            equipment_desc: item,
          });
        });
      },
      deep: true,
    },
    startTime: {
      handler() {
        if(this.startTime) {
          this.fieldService.start_time = Utils.formatTime(this.startTime as Date);
        }
      },
    },
    endTime: {
      handler() {
        if(this.endTime) {
          this.fieldService.end_time = Utils.formatTime(this.endTime as Date);
        }
      },
    },
  },
});
